<template>
  <div class="line inline-block">
    <AddCountryButton
      ref="addCountryButton"
      style="text-align: left"
      :resetData="data"
      :disabledList="disabledList"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #btn="{ scoped: { showDialog } }">
        <ColorTextBtn type="primary"  @click="to(showDialog)">复制</ColorTextBtn>
      </template>
    </AddCountryButton>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import AddCountryButton from './addCountryButton'

export default {
  components: {
    AddCountryButton
  },

  props: {
    row: {
      type: Object,
      required: true
    },

    disabledList: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      data: [],
    }
  },

  methods: {
    async to(showDialog) {
      const { countryId } = this.row
      const selecedData = this.disabledList.filter((item) => item.countryId == countryId)
      const detail = cloneDeep(selecedData)
      this.data = detail.map((item) => {
        delete item.id
        delete item.countryName
        delete item.countryId
        return item
      })
      this.$nextTick(() => {
        showDialog()
      })
    }
  }
}
</script>

<style></style>
